.dateWrap{
    width:80px;
    text-align:center;
    color:#fff ;
}
.day {
    font-size:25px;
    background-color:#828D95;
    height:60;
    line-height:60px;
    margin-bottom:1px;
    transition: all 0.5s; 
}

.dateWrap:hover .day{
    background-color: #E1FAEB;
    color: #00b96b;
}
.dateWrap:hover .year{
    background-color: #00b96b;
}
.year {
    font-size:16;
    background-color:#ccc;
    transition: all 0.5s; 
}